<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div>
          <h5 style="color: blue; margin-bottom: 30px">
            {{ $t("smartSchedule.smartSchedules") }}
          </h5>
          <div class="row">
            <div class="col-12">
              <a-table
                :loading="tableLeading"
                :rowKey="'_id'"
                :data-source="data"
                :columns="columns"
                :pagination="true"
                :scroll="{ x: 'max-content' }"
              >
                <div slot="starttime" slot-scope="text, record">
                  <a-tag v-if="record.starttime">
                    {{
                      moment(record.starttime).format("DD/MM/YYYY HH:mm")
                    }}</a-tag
                  >
                  <a-tag v-else>--</a-tag>
                </div>
                <div slot="finishtime" slot-scope="text, record">
                  <a-tag v-if="record.finishtime">
                    {{
                      moment(record.finishtime).format("DD/MM/YYYY HH:mm")
                    }}</a-tag
                  >
                  <a-tag v-else>--</a-tag>
                </div>
                <div slot="status" slot-scope="text">
                  <a-tag color="orange" v-if="text == 'pending'">
                    {{ $t("smartSchedule.pending") }}
                  </a-tag>
                  <a-tag color="green" v-if="text == 'finished'">
                    {{ $t("smartSchedule.finished") }}
                  </a-tag>
                  <a-tag color="blue" v-if="text == 'init'">
                    {{ $t("smartSchedule.ready") }}
                  </a-tag>
                  <a-tag color="red" v-if="text == 'error'">
                    {{ $t("smartSchedule.error") }}
                  </a-tag>
                </div>
                <div slot="action" slot-scope="text, record">
                  <a-button
                    :type="record.status != 'finished' ? '' : 'primary'"
                    @click="
                      () => {
                        handleClick(record._id);
                      }
                    "
                    :disabled="record.status != 'finished'"
                    icon="eye"
                  >
                    {{ $t("action.voir") }}
                  </a-button>
                </div>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
export default {
  name: "smart-schedule",
  async created() {
    await apiClient
      .post("/smartSchedule/schedJobParam/filter", {
        query: {},
        aggregation: [{ $sort: { createdAt: -1 } }],
      })
      .then(({ data }) => {
        this.data = data;
      })
      .finally(() => {
        this.tableLeading = false;
      });
  },
  data() {
    return {
      teachers: [],
      tableLeading: true,
      data: [],
      loadingSave: false,
      visibleModal: false,
      moment,
      columns: [
        {
          title: this.$t("smartSchedule.name"),
          dataIndex: "name",
          sorter: true,
          key: "name",
          sorter: (a, b) => a.name.length - b.name.length,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            customRender: "name",
          },
        },
        {
          title: this.$t("all.startDate"),
          dataIndex: "starttime",
          key: "starttime",
          sorter: (a, b) => a - b,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            customRender: "starttime",
          },
        },
        {
          title: this.$t("all.endDate"),
          dataIndex: "finishtime",
          key: "finishtime",
          sorter: (a, b) => a - b,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            customRender: "finishtime",
          },
        },
        {
          title: this.$t("topBar.status"),
          dataIndex: "status",
          key: "status",
          sorter: (a, b) => a.status.length - b.status.length,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            customRender: "status",
          },
        },
        {
          title: this.$t("action.voir"),
          dataIndex: "action",
          key: "action",
          scopedSlots: {
            customRender: "action",
          },
        },
      ],
    };
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    filterOptionGroup(input, option) {
      if (option.componentOptions.children[0].text)
        return (
          option.componentOptions.children[0].text
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        );
      return false;
    },
    showModal() {
      this.visibleModal = true;
    },
    handleCancel() {
      this.form.resetFields();
      this.visibleModal = false;
    },
    handleClick(id) {
      console.log(id);
      this.$router.push({ path: `/smart-schedule/${id}` });
    },
  },
};
</script>
